import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import Img from 'gatsby-image';

const Root = styled.div`
  width: 49%;
  margin: 0.5%;
  flex-shrink: 0;
  @media ${(p) => p.theme.lg} {
    width: 32%;
    margin-bottom: 20px;
  }

  @media ${(p) => p.theme.sm} {
    width: 100%;
    margin-bottom: 20px;
  }
`;

const Image = styled(Img)`
  width: 100%;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
`;

const BtnGroup = styled.div`
  display: flex;
  align-items: stretch;
`;
const Btn = styled.div`
  background: #eee;
  padding: 15px 10px;
  text-align: center;
  width: 100%;
  cursor: pointer;
  &:first-child {
    border-bottom-left-radius: 6px;
  }
  &:last-child {
    border-bottom-right-radius: 6px;
  }
  ${(p) =>
    p.active &&
    css`
      background: ${() => p.theme.primary};
      color: #fff;
      font-weight: 600;
    `};
`;

const ImageWrapper = styled.div``;

export default function SampleImage({ image, heatmap, warped }) {
  const [active, setActive] = useState('original');
  const setState = (v) => () => {
    setActive(v);
  };

  if (!image || !heatmap || !warped) return null;
  return (
    <Root>
      <ImageWrapper>
        {active === 'original' && <Image fluid={image.childImageSharp.fluid} />}
        {active === 'heatmap' && (
          <Image fluid={heatmap.childImageSharp.fluid} />
        )}
        {active === 'warped' && <Image fluid={warped.childImageSharp.fluid} />}
      </ImageWrapper>
      <BtnGroup>
        <Btn active={active === 'original'} onClick={setState('original')}>
          Original
        </Btn>
        <Btn
          active={active === 'warped'}
          onClick={setState('warped')}
          loading="eager"
        >
          Undo-ed
        </Btn>
        <Btn
          active={active === 'heatmap'}
          onClick={setState('heatmap')}
          loading="eager"
        >
          Heatmap
        </Btn>
      </BtnGroup>
    </Root>
  );
}
