import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';
import SampleImage from '../../components/SampleImage';
import Container from '../../components/Container';
import 'react-toastify/dist/ReactToastify.css';

const Root = styled.div`
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const query = graphql`
  query SampleImagesQuery {
    site {
      siteMetadata {
        siteUrl: url
        samplesUrl
      }
    }

    originals: allFile(
      filter: { relativePath: { regex: "/sample-images/original/" } }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    heatmaps: allFile(
      filter: { relativePath: { regex: "/sample-images/heatmap/" } }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }

    warps: allFile(
      filter: { relativePath: { regex: "/sample-images/warped/" } }
    ) {
      nodes {
        base
        childImageSharp {
          fluid(maxWidth: 800, maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`;

export default function SamplesPage(props) {
  const originals = props.data.originals.nodes;
  const heatmaps = props.data.heatmaps.nodes;
  const warps = props.data.warps.nodes;

  const mapped = originals.map((item) => {
    const id = item.base.split('face-')[1].slice(0, -4);
    return {
      ...item,
      id,
      heatmap: heatmaps.find((x) => x.base === `face-${id}-heatmap.jpg`),
      warped: warps.find((x) => x.base === `face-${id}-warped.jpg`),
    };
  });
  const { siteUrl, samplesUrl } = props.data.site.siteMetadata;
  const baseUrl = `${siteUrl}${samplesUrl}`;
  return (
    <Layout>
      <SEO title="Sample Images" />
      <ToastContainer
        position="top-center"
        autoClose={1500}
        hideProgressBar
        closeOnClick
      />
      <Container>
        <h1>Samples</h1>
        <p>
          <strong>Original</strong> shows photo after manipulations,{' '}
          <strong>undo-ed</strong> photo shows result of AI reconstruction of
          the photo before editing, and <strong>heatmap</strong> shows
          highlights where manipulations were detected.
        </p>
        <p>
          All images are licensed under{' '}
          <a href="https://help.unsplash.com/en/collections/1463188-unsplash-license">
            Unsplash license
          </a>
          .
        </p>

        <Root>
          {mapped.map((img) => (
            <SampleImage
              image={img}
              key={img.base}
              heatmap={img.heatmap}
              warped={img.warped}
              baseUrl={baseUrl}
            />
          ))}
        </Root>
      </Container>
    </Layout>
  );
}
